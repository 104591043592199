<template>
  <el-dialog
    :visible.sync="edit.visible"
    :title="edit.isEdit ? '编辑' : '新增'"
    width="500px"
  >
    <el-form
      v-if="edit.visible"
      ref="form"
      :model="edit.data"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="应用名" prop="app_name">
        <el-input v-model="edit.data.app_name" />
      </el-form-item>
      <el-form-item label="包名" prop="package_id">
        <el-input v-model="edit.data.package_id" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="edit.data.comment" />
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button @click="edit.visible = false"> 取消 </el-button>
      <el-button
        type="primary"
        :disabled="edit.loading || edit.saving"
        @click="onSubmit"
      >
        保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { store } from './store'

export default {
  components: {},

  data() {
    return {
      store,
      edit: store.edit,
      rules: {
        app_name: { required: true, message: '请填写应用名' },
        package_id: { required: true, message: '请填写包名' }
      }
    }
  },

  methods: {
    async onSubmit() {
      await this.$refs.form.validate()
      store.edit.onEditSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/admin-shared-modules/theme/var';
</style>
