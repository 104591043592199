import _ from 'lodash'

export const isDEV = !!~location.host.indexOf('dev.los')

// for orders
export const ReaderType = {
  P60Q: '6寸纯平超薄版(P60Q)',
  P97AL: '9.7寸(P97AL)(不带笔)',
  P10: '10寸(P10E)'
}
export type ReaderTypeKey = keyof typeof ReaderType
export const ReaderTypeOptions = _.map(ReaderType, (value, key) => {
  return { key: key as ReaderTypeKey, value }
})

export type OTAReaderTypeKey = string

export const ShelfType = {
  '120': '120门智能借还柜',
  '60': '60门智能借还柜',
  '30': '30门智能借还柜'
}
export type ShelfTypeKey = keyof typeof ShelfType
export const ShelfTypeOptions = _.map(ShelfType, (value, key) => {
  return { key: key as ShelfTypeKey, value }
})

export const BatteryShelfType = {
  USB60: 'USB60',
  OTHER: '其它'
}
export type BatteryShelfTypeKey = keyof typeof BatteryShelfType
export const BatteryShelfTypeOptions = _.map(BatteryShelfType, (value, key) => {
  return { key: key as BatteryShelfTypeKey, value }
})

export const SalesDepartment = {
  公图: '公图',
  教图: '教图',
  教务: '教务',
  中小学: '中小学',
  其它: '其它'
}
export const SalesDepartmentOptions = _.keys(SalesDepartment)

export const LibType = [
  { value: 1, label: '公图' },
  { value: 2, label: '教图' },
  { value: 4, label: '党建' },
  { value: 10, label: '医学' },
  { value: 22, label: '基础教育家长' },
  { value: 23, label: '基础教育教师' },
  { value: 7, label: '小学' },
  { value: 8, label: '初中' },
  { value: 9, label: '高中' },
  { value: 78, label: '小初' },
  { value: 89, label: '初高' },
  { value: 789, label: '小初高' },
  { value: 12, label: '中职' },
  { value: 28, label: '老年' }
]
export const LibTypeOptions = _.map(LibType, ({ value, label }) => {
  return { key: value, value: label }
})

export const CustomerTypeOptions = _.map(
  [
    '公共图书馆',
    '985 211 本科',
    '本科',
    '研究所',
    '党校',
    '电大',
    '高职高专',
    '中职中专',
    '军校',
    '事业单位',
    '政府',
    '中小学',
    '机构(代理商)'
  ],
  name => {
    return { key: name, value: name }
  }
)

export const ApplyType = {
  1: '合同订单',
  2: '试用订单',
  3: '赠送订单'
}
export const ApplyTypeOptions = _.map(ApplyType, (value, key) => {
  return { key, value }
})

export const OrderStatus = {
  0: '欠货订单',
  1: '全部订单'
}
export const OrderStatusOptions = _.map(OrderStatus, (value, key) => {
  return { key, value }
})

export const ContractType = {
  1: 'kindle升级',
  2: '阅读本',
  3: '自助借还'
}
export const ContractTypeOptions = _.map(ContractType, (value, key) => {
  return { key, value }
})

export const TagType = {
  1: '系统图书',
  2: '馆方图书'
}
export const TagTypeOptions = _.map(TagType, (value, key) => {
  return { key, value }
})

export type BookTypeRootKey = 'public' | 'junior' | 'CPC' | 'medical'
export const BookTypeRoot = [
  { value: 'public', label: '图书' },
  { value: 'junior', label: '中职' },
  { value: 'CPC', label: '党建' },
  { value: 'medical', label: '医学' }
]

export const LibAppType = [
  { value: 8, label: '书城' },
  { value: 4, label: '课程' },
  { value: 5, label: '消息通知' },
  { value: 7, label: '阅读计划' },
  { value: 9, label: '阅读报告' },
  // Reader dashboard
  { value: 101, label: 'AI笔记' },
  { value: 102, label: '随手记' },
  { value: 103, label: '练字本' },
  { value: 104, label: 'OCR识别' },
  { value: 105, label: '词典' },
  { value: 106, label: '文件浏览' },
  { value: 107, label: '系统设置' },
  { value: 108, label: '个人中心' },
  { value: 109, label: '全部应用' }
]

export const LibLoginType = [
  { value: 1, label: '学习通扫码' },
  { value: 2, label: '机构账号' },
  { value: 5, label: '超星号' },
  { value: 4, label: '选学生' },
  { value: 3, label: '无登录' },
  { value: 6, label: '第三方登录' }
]

export const MagazineTypeRoot = [
  { value: 1, label: '公图' },
  { value: 2, label: '教图' },
  { value: 4, label: '党建' },
  { value: 10, label: '医学' },
  { value: 7, label: '小学' },
  { value: 8, label: '初中' },
  { value: 9, label: '高中' },
  { value: 23, label: '教师' },
  { value: 28, label: '老年' }
]
