<template>
  <page-layout v-loading="list.loading">
    <page-header :breadcrumb="['应用', '应用黑名单']" />

    <page-main>
      <page-action-bar>
        <el-button type="primary" size="small" @click="e => edit.onAdd()">
          添加
        </el-button>
      </page-action-bar>

      <el-table ref="table" :data="list.items">
        <el-table-column label="应用名" prop="app_name"></el-table-column>
        <el-table-column label="包名" prop="package_id"></el-table-column>
        <el-table-column label="备注" prop="comment"></el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="e => edit.onEdit(row)">
              编辑
            </el-button>
            <el-button type="text" @click="e => edit.onRemove(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="i => list.handlePageIndexChange(i)"
        :current-page="list.index"
        :total="list.total"
        :page-size="list.size"
        layout="total, prev, pager, next"
        background
      />
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts">
import Edit from './edit.vue'
import { store } from './store'

export default {
  components: {
    Edit
  },

  data() {
    return {
      store,
      list: store.list,
      edit: store.edit
    }
  },
  created() {
    this.list.fetch({ reset: true })
  },
  methods: {}
}
</script>

<style scoped></style>
